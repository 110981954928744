import styled from "styled-components"

const Grid = styled.div`
  display: grid;
  --auto-grid-min-size: 16rem;
  --gap: 2rem 0.4rem;
  display: grid;
  margin-top: 36px;
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--auto-grid-min-size), 1fr)
  );
  gap: var(--gap);
`

export default Grid
