import React from "react"

const ExternalLink = ({ href, children, alt, ...other }) => (
  <a
    href={href}
    {...other}
    rel="noreferrer noopener"
    target="_blank"
    aria-label={alt}
  >
    {children}
  </a>
)

export default ExternalLink
