import styled from "styled-components"
import { Container } from "../global"

export const Form = styled.form`
  display: flex;
  flex-direction: row;
  gap: 2em;
  align-items: center;
  @media (max-width: ${props => props.theme.screen.sm}) {
    flex-direction: column;
  }
`
export const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  gap: 1em;
  text-align: center;
  align-items: center;
`

export const FooterText = styled.p`
  color: var(--color-text-light);
  font-size: var(--font-size-small);
  line-height: var(--line-height-small);
`

export const Input = styled.input`
  padding: 1rem 0.5rem;
  width: calc(min(40ch, 70vw));
`

export const Button = styled.button`
  padding: 1rem 1rem;
  background: var(--color-highlight);
  color: var(--color-primary);
`
