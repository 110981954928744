import styled from "styled-components"

const Card = styled.article`
  --card-height: 40vh;
  --card-width: 20%;
  --gap: 1rem;
  display: grid;
  gap: var(--gap);
  ${props =>
    props.compact
      ? `
  grid-template-columns: var(--card-width) auto;
  grid-auto-flow: column;
  `
      : `
  grid-template-rows: var(--card-height) auto;
  grid-auto-flow: row;
  `}
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    grid-template-rows: var(--card-height) auto;
    grid-auto-flow: row;
  }
`

export const CardBody = styled.div`
  --card-body-gap: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: var(--card-body-gap);
  & * {
    margin: 0;
  }
`

export default Card
