import React, { useState } from "react"
import { Section } from "../global"
import { Button, FooterText, Form, Input, StyledContainer } from "./style"

const SubscriptionForm = () => {
  const [status, setStatus] = useState(null)
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [loading, setLoading] = useState(false)

  const FORM_URL = process.env.GATSBY_FORM_URL

  const handleSubmit = async e => {
    e.preventDefault()
    setLoading(true)
    const data = new FormData(e.target)
    try {
      await fetch(FORM_URL, {
        method: "post",
        body: data,
        mode: "no-cors",
        headers: {
          accept: "application/json",
        },
      })
      setStatus("SUCCESS")
    } catch (err) {
      console.log(err)
      setStatus("ERROR")
    } finally {
      setLoading(false)
    }
  }

  const handleInputChange = field => {
    const setter = field === "email" ? setEmail : setName
    return event => {
      const { value } = event.target
      setter(value)
    }
  }

  return (
    <Section accent="secondary">
      <StyledContainer>
        <h2>Hear from me 🗞️</h2>
        <p>
          I write a new article usually once a month. Whenever I publish, you
          will get an email in your inbox.
        </p>
        {status === "SUCCESS" && (
          <p>
            All done 🎉! Please check your email to confirm the subscription.
          </p>
        )}
        {status === "ERROR" && (
          <p>
            Oops, Something went wrong 😔!{" "}
            <button onClick={() => setStatus(null)}>Try again</button>.
          </p>
        )}

        {status === null ? (
          <Form action={FORM_URL} method="post" onSubmit={handleSubmit}>
            <Input
              type="text"
              aria-label="Name"
              name="name"
              placeholder="Name"
              onChange={handleInputChange("name")}
              value={name}
              required
            />
            <Input
              type="email"
              aria-label="Email"
              name="email"
              placeholder="Email address"
              onChange={handleInputChange("email")}
              value={email}
              required
            />
            <Input
              style={{ display: "none" }}
              type="checkbox"
              name="l"
              checked
              value="e5d3f432-89be-4705-a789-e7175f3793b1"
              readOnly
            />
            <Button type="submit" disabled={loading}>
              {loading ? "..." : "Subscribe"}
            </Button>
          </Form>
        ) : (
          []
        )}

        <FooterText>
          No spam, no selling of your personal data. Unsubscribe at any time.
        </FooterText>
      </StyledContainer>
    </Section>
  )
}

export default SubscriptionForm
