import React, { useState } from "react"
import { Link } from "gatsby"

import { Container } from "../global"
import {
  Nav,
  NavItem,
  StyledContainer,
  NavListWrapper,
  MobileMenu,
  Mobile,
} from "./style"
import { colors } from "../../styles/theme"

import MenuIcon from "../../../static/icons/menu.svg"
import Sun from "../../../static/icons/sun.svg"
import Moon from "../../../static/icons/moon.svg"
import { attachToRootStyle, getCurrentTheme } from "../../utils"

const NAV_ITEMS = [
  { name: "Writings", target: "/writings" },
  { name: "Photography", target: "/photography" },
]

export default function Navbar(props) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [colorTheme, setColorTheme] = React.useState("")

  React.useEffect(() => {
    const currentTheme = getCurrentTheme()
    setColorTheme(currentTheme)
  }, [])

  const toggleTheme = () => {
    const currentTheme = getCurrentTheme()
    const target = currentTheme === "light" ? "dark" : "light"
    attachToRootStyle("color", colors[target])
    localStorage.setItem("COLOR_THEME", target)
    setColorTheme(target)
    setMobileMenuOpen(false)
  }

  const getNavList = ({ mobile = false }) => (
    <NavListWrapper mobile={mobile}>
      <ul>
        {NAV_ITEMS.map(({ name, target }) => (
          <NavItem key={name}>
            <Link to={target} activeClassName="active" partiallyActive={true}>
              {name}
            </Link>
          </NavItem>
        ))}
        <NavItem onClick={() => toggleTheme()}>
          {colorTheme === "light" && <Moon />}
          {colorTheme === "dark" && <Sun />}
        </NavItem>
      </ul>
    </NavListWrapper>
  )
  return (
    <Nav {...props} mobileMenuOpen={mobileMenuOpen}>
      <StyledContainer>
        <h4>
          <Link to="/" className="display">
            Mountainology
          </Link>
        </h4>
        <Mobile>
          <button
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            style={{ color: "var(--color-text)", fontSize: "initial" }}
            aria-label="menu"
          >
            <MenuIcon style={{ width: "24px", height: "24px" }} />
          </button>
        </Mobile>

        <Mobile hide>{getNavList({})}</Mobile>
      </StyledContainer>
      <Mobile>
        {mobileMenuOpen && (
          <MobileMenu>
            <Container>{getNavList({ mobile: true })}</Container>
          </MobileMenu>
        )}
      </Mobile>
    </Nav>
  )
}
