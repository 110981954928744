import styled from "styled-components"

import { Container } from "../global"

export const SocialIcons = styled.div`
  display: flex;

  a {
    color: var(--color-text);
  }

  & svg {
    margin: 0 8px;
    width: 24px;
    height: 24px;
  }

  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-top: 40px;
  }
`

export const FooterWrapper = styled.footer`
  background-color: var(--color-accent);
  padding: 32px 0;
`

export const Copyright = styled.div`
  font-family: var(--font-secondary);
  font-size: var(--font-size-small);
  line-height: var(--line-height-small);
  color: var(--color-text);

  a {
    text-decoration: none;
    color: inherit;
  }
`

export const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${props => props.theme.screen.sm}) {
    flex-direction: column;
    text-align: center;
  }
`
