import styled from "styled-components"

export const Nav = styled.nav`
  padding: 8px 0;
  ${props =>
    props.transperent ? `` : `background-color: var(--color-accent); `}
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 1000;
  ${props =>
    props.mobileMenuOpen ? `background-color: var(--color-accent); ` : ""}
`

export const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;
`

export const NavListWrapper = styled.div`
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;

    ${({ mobile }) =>
      mobile &&
      `
        flex-direction: column;
        margin-top: 1em;
        align-items: center;
        height: 100vh;

        > ${NavItem} {
          margin: 0;
          margin: 2em;
          font-size: 1.5rem;
        }
      `};
  }
`

export const NavItem = styled.li`
  display: flex;
  align-items: center;
  margin: 0 0.75em;
  font-family: var(--font-display);
  font-size: 1rem;
  font-weight: 500;

  a {
    text-decoration: none;
    color: var(--color-text);
    opacity: 0.7;
    &.active {
      opacity: 1;
    }
  }
`

export const MobileMenu = styled.div`
  width: 100%;
  background: var(--color-accent);
`

export const Mobile = styled.div`
  display: none;

  @media (max-width: ${props => props.theme.screen.md}) {
    display: block;
  }

  ${props =>
    props.hide &&
    `
    display: block;

    @media (max-width: ${props.theme.screen.md}) {
      display: none;
    }
  `}
`
