import React from "react"

import ExternalLink from "../ExternalLink"
import GithubIcon from "../../../static/icons/github.svg"
import LinkedinIcon from "../../../static/icons/linkedin.svg"
import GmailIcon from "../../../static/icons/gmail.svg"

import { SocialIcons, FooterWrapper, Copyright, StyledContainer } from "./style"
import SubscriptionForm from "../SubscriptionForm"

const SOCIAL = [
  {
    Icon: GithubIcon,
    link: "https://github.com/vinayakakv",
    alt: "GitHub Profile",
  },
  {
    Icon: LinkedinIcon,
    link: "https://www.linkedin.com/in/vinayakakv/",
    alt: "LinkedIn Profile",
  },
  {
    Icon: GmailIcon,
    link: "mailto:me.vinayakakv+from_website@gmail.com",
    alt: "Email",
  },
]

const Footer = () => (
  <>
    <SubscriptionForm />
    <FooterWrapper>
      <StyledContainer>
        <Copyright>
          <span>&copy; 2021-present Vinayaka K V. All Rights Reserved.</span>
        </Copyright>
        <SocialIcons id="social">
          {SOCIAL.map(({ Icon, link, alt }) => (
            <ExternalLink key={link} href={link} alt={alt}>
              <Icon />
            </ExternalLink>
          ))}
        </SocialIcons>
      </StyledContainer>
    </FooterWrapper>
  </>
)

export default Footer
